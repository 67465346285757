exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-debug-js": () => import("./../../../src/pages/debug.js" /* webpackChunkName: "component---src-pages-debug-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interests-js": () => import("./../../../src/pages/interests.js" /* webpackChunkName: "component---src-pages-interests-js" */),
  "component---src-pages-introduction-js": () => import("./../../../src/pages/introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */),
  "component---src-pages-suggestions-js": () => import("./../../../src/pages/suggestions.js" /* webpackChunkName: "component---src-pages-suggestions-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

